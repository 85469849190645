@import "../constants/fonts.scss";

@mixin bold-font {
  font-family: $var-font-pluto-sans-bold;
}

@mixin light-font {
  font-family: $var-font-pluto-sans-light;
}

@mixin medium-font {
  font-family: $var-font-pluto-sans-medium;
}

@mixin regular-font {
  font-family: $var-font-pluto-sans;
}
