// New
$spacing-3xs: 4px;
$spacing-2xs: 8px;
$spacing-xs: 12px;
$spacing-s: 16px;
$spacing-sm: 24px;
$spacing-md: 32px;
$spacing-lg: 40px;
$spacing-xl: 48px;
$spacing-2xl: 56px;
$spacing-3xl: 64px;
$spacing-4xl: 72px;
$spacing-5xl: 80px;

// Obsolete
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-56: 56px;
$spacing-64: 64px;
$spacing-80: 80px;
$spacing-100: 100px;
