@import "../breakpoints.module.scss";

// New Design: https://www.figma.com/file/m0ZGcb6bl29nGbJWJYXvkl/01---HKE---Design-system?type=design&node-id=1-200&mode=design&t=lfh4L66eylpYJBVE-0
// Obsolete
@mixin heading-size {
  font-size: 32px;
}

@mixin heading-small-size {
  font-size: 24px;
}

@mixin subtitle-size {
  font-size: 22px;
}

@mixin subtitle-small-size {
  font-size: 18px;
}

@mixin paragraph-size {
  font-size: 16px;
}

@mixin paragraph-small-size {
  font-size: 14px;
}

@mixin body-1-size {
  font-size: 18px;
}

@mixin body-2-size {
  font-size: 16px;
}

@mixin body-3-size {
  font-size: 14px;
}

@mixin body-4-size {
  font-size: 13px;
}

@mixin body-5-size {
  font-size: 11px;
}

// New
@mixin h0-display-large-size {
  font-size: 44px;
  line-height: 48px;

  @include breakpoints(mobile) {
    font-size: 28px;
    line-height: 34px;
  }
}

@mixin h1-display-medium-size {
  font-size: 32px;
  line-height: 40px;

  @include breakpoints(mobile) {
    font-size: 24px;
    line-height: 34px;
  }
}

@mixin h2-headline-size {
  font-size: 26px;
  line-height: 32px;

  @include breakpoints(mobile) {
    font-size: 20px;
    line-height: 28px;
  }
}

@mixin h3-title-size {
  font-size: 22px;
  line-height: 32px;

  @include breakpoints(mobile) {
    font-size: 18px;
    line-height: 26px;
  }
}

@mixin p1-size {
  font-size: 16px;
  line-height: 24px;
}

@mixin p2-size {
  font-size: 14px;
  line-height: 20px;
}

@mixin p3-size {
  font-size: 12px;
  line-height: 16px;
}
