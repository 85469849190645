@import '@hkexpressairwayslimited/ui/styles/global';

.jssRichText {
  ul,
  ol {
    padding: 0 $spacing-xs;
    margin: $spacing-2xs 0;
    list-style-position: inside;

    :global(.rte-indent-1) {
      padding-left: $spacing-2xs * 3;
    }
    :global(.rte-indent-2) {
      padding-left: $spacing-2xs * 3 * 2;
    }
    :global(.rte-indent-3) {
      padding-left: $spacing-2xs * 3 * 3;
    }
    :global(.rte-indent-4) {
      padding-left: $spacing-2xs * 3 * 4;
    }
    :global(.rte-indent-5) {
      padding-left: $spacing-2xs * 3 * 5;
    }
    :global(.rte-indent-6) {
      padding-left: $spacing-2xs * 3 * 6;
    }
    :global(.rte-indent-7) {
      padding-left: $spacing-2xs * 3 * 7;
    }
    :global(.rte-indent-8) {
      padding-left: $spacing-2xs * 3 * 8;
    }
  }

  ol li,
  ul li {
    margin-bottom: $spacing-2xs;
  }

  ol li::marker {
    @include p1-regular;
    @include bold-font;
    color: #702b91;
    margin-right: $spacing-2xs;
  }

  ul li::marker {
    font-size: $spacing-sm;
    color: #702b91;
    margin-right: $spacing-2xs;
  }
}
